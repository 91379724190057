import Service from './service';

class PositionListingService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getPositionListings() {
    const response = await this.api
      .get(
        'position-listings',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getPositionListing(id) {
    const response = await this.api
      .get(
        `position-listings/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createPositionListing(
    name,
    description,
    customerId,
    openings,
    dateOpened,
    priorityId,
  ) {
    const data = {
      listing: {
        name,
        description,
        customer_id: customerId,
        openings,
        date_opened: dateOpened,
        priority_id: priorityId,
      },
    };

    const response = await this.api
      .post(
        'position-listings',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updatePositionListing(
    id,
    name,
    description,
    customerId,
    openings,
    dateOpened,
    priorityId,
  ) {
    const data = {
      listing: {
        name,
        description,
        customer_id: customerId,
        openings,
        date_opened: dateOpened,
        priority_id: priorityId,
      },
    };

    const response = await this.api
      .put(
        `position-listings/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deletePositionListing(id) {
    const response = await this.api
      .delete(
        `position-listings/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new PositionListingService();
