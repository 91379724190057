import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import { CardActionArea, Chip } from '@mui/material';
import Card from '@mui/material/Card';

import SuiTypography from 'components/SuiTypography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function ApplicationPositionCard({ position, openFunction }) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const [stage, setStage] = useState({ color: '#f7ac3b', label: t('applied') });

  useEffect(() => {
    switch (position.recruitment_stage_id) {
      case 1:
        setStage({ color: '#f7ac3b', label: t('applied') });
        break;
      case 2:
        setStage({ color: '#3f63f2', label: t('screening') });
        break;
      case 3:
        setStage({ color: '#8436d1', label: t('interview') });
        break;
      case 4:
        setStage({ color: '#28a4c9', label: t('offered') });
        break;
      case 5:
        setStage({ color: '#2d9e29', label: t('hired') });
        break;
      case 6:
        setStage({ color: '#c9283e', label: t('rejected') });
        break;
      default:
        break;
    }
  }, [position.recruitment_stage_id, t]);

  return (
    <Card>
      <CardActionArea onClick={openFunction}>
        <Grid
          container
          direction="row"
          p={2}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Grid item md={4}>
            <SuiTypography
              fontWeight="bold"
              variant="h5"
            >
              {position.position_listing.name}
            </SuiTypography>
          </Grid>
          <Grid item md={4}>
            <Chip sx={{ bgcolor: stage.color }} label={stage.label} />
          </Grid>
          <Grid item md={4} />
          <Grid item md={4}>
            <SuiTypography
              variant="subtitle1"
              fontWeight="regular"
            >
              {position.position_listing.customer_name}
            </SuiTypography>
          </Grid>
          <Grid item md={4}>
            {position.start_date}
          </Grid>
          <Grid item md={4} />
          <Grid item md={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                {position.position_listing.state !== '' && (
                  <SuiTypography
                    variant="subtitle2"
                    fontWeight="regular"
                  >
                    <FontAwesomeIcon icon={faLocationDot} />
                    {' '}
                    {position.position_listing.state !== '' ? `${position.position_listing.city}, ${position.position_listing.state}` : ''}
                  </SuiTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={8} />
        </Grid>
      </CardActionArea>
    </Card>
  );
}

ApplicationPositionCard.defaultProps = {
  openFunction: null,
};

ApplicationPositionCard.propTypes = {
  position: PropTypes.shape(
    {
      recruitment_stage_id: PropTypes.number,
      start_date: PropTypes.string,
      position_listing: PropTypes.shape(
        {
          id: PropTypes.number,
          date_opened: PropTypes.string,
          description: PropTypes.string,
          name: PropTypes.string,
          openings: PropTypes.number,
          priority_id: PropTypes.number,
          status_id: PropTypes.number,
          customer_id: PropTypes.number,
          customer_name: PropTypes.string,
          state: PropTypes.string,
          city: PropTypes.string,
        },
      ),
    },
  ).isRequired,
  openFunction: PropTypes.func,
};

export default ApplicationPositionCard;
