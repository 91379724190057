import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// prop-types is library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
import { DropzoneDialog } from 'material-ui-dropzone';
import Modal from '@mui/material/Modal';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Util functions
import { getUrlExtension } from 'Util';

// Other Components

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function DefaultDocumentCard(
  {
    key,
    id,
    candidateId,
    title,
    expanded,
    description,
    fileUrl,
    filesLimit,
    maxFilesSize,
    changeFunc,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.documents' });

  const [expandedState, setExpandedState] = useState(expanded);
  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpandedState(!expandedState);
  };

  const handleChange = (files, docId) => {
    changeFunc(files, candidateId, docId);

    setOpen(false);
  };

  return (
    <Card key={key}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={title}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expandedState
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        {removeFunc !== undefined && (
          <MenuItem
            onClick={() => {
              removeFunc(id, candidateId);
            }}
          >
            {t('remove', { keyPrefix: 'common' })}
          </MenuItem>
        )}
      </Menu>
      <Collapse in={expandedState} timeout="auto" unmountOnExit>
        <CardContent>
          <SuiBox>
            {description !== null ? (
              <SuiBox mb={2}>
                <SuiTypography variant="body2" color="text">
                  {description}
                </SuiTypography>
              </SuiBox>
            ) : ''}
            {(getUrlExtension(fileUrl) === 'pdf') && (
              <SuiBox
                onClick={handleOpenModal}
              >
                <iframe
                  src={fileUrl}
                  title="pdf"
                  width="100%"
                  height="400px"
                />
              </SuiBox>
            )}
            {(getUrlExtension(fileUrl) !== 'pdf') && (
              <SuiBox
                component="img"
                src={fileUrl}
                shadow="lg"
                borderRadius="xl"
                width="100%"
                onClick={handleOpenModal}
              />
            )}
            <CardActions>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <SuiButton variant="text" color="dark" onClick={() => setOpen(true)}>
                    {t('add-document')}
                  </SuiButton>
                </Grid>
                <Grid item>
                  <SuiButton variant="text" color="dark" onClick={handleOpenModal}>
                    {t('open', { keyPrefix: 'common' })}
                  </SuiButton>
                </Grid>
              </Grid>
            </CardActions>
          </SuiBox>
        </CardContent>
      </Collapse>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SuiBox
          sx={style}
          borderRadius="xl"
        >
          {(getUrlExtension(fileUrl) === 'pdf') ? (
            <SuiBox>
              <iframe
                src={fileUrl}
                title="pdf"
                width="100%"
                height="600px"
              />
            </SuiBox>
          ) : (
            <SuiBox
              component="img"
              src={fileUrl}
              width="100%"
            />
          )}
        </SuiBox>
      </Modal>
      <DropzoneDialog
        acceptedFiles={['image/*', 'application/pdf']}
        cancelButtonText={t('cancel')}
        submitButtonText={t('upload')}
        maxFileSize={maxFilesSize}
        filesLimit={filesLimit}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          handleChange(files, id);
        }}
        showPreviews
        showFileNamesInPreview
      />
    </Card>
  );
}

// Setting admin values for the props of DefaultInfoCard
DefaultDocumentCard.defaultProps = {
  key: '',
  expanded: true,
  description: null,
  fileUrl: '',
  filesLimit: 1,
  maxFilesSize: 15728640,
  removeFunc: undefined,
};

// Typechecking props for the DefaultInfoCard
DefaultDocumentCard.propTypes = {
  key: PropTypes.string,
  id: PropTypes.number.isRequired,
  candidateId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  description: PropTypes.string,
  fileUrl: PropTypes.string,
  filesLimit: PropTypes.number,
  maxFilesSize: PropTypes.number,
  changeFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func,
};

export default DefaultDocumentCard;
