import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducers functions
import { setCustomerInfo } from 'reducers/customerSlice';
import { fetchCities } from 'reducers/commonSlice';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiTagInput from 'components/SuiTagInput';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import TabBar from 'layouts/components/TabBar';

// Util functions
import { findSelectValue } from 'Util';

const selector = (state) => ({
  customerInfo: state.customer.customerInfo,
  attorneys: state.attorney.attorneys,
  states: state.common.states,
  cities: state.common.cities,
});

function CustomerInfo() {
  const { t } = useTranslation('translation', { keyPrefix: 'customers' });

  const {
    customerInfo,
    attorneys,
    states,
    cities,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [currentTabView, setCurrentTabView] = useState(t('customer-info'));

  useEffect(() => {
    if (customerInfo !== undefined
      && customerInfo.state_id !== null
      && customerInfo.state_id !== undefined) {
      dispatch(
        fetchCities({
          countryId: 233,
          stateId: customerInfo.state_id,
        }),
      );
    }
  }, [customerInfo, dispatch]);

  const tabs = [t('customer-info'), t('attorney'), t('tags')];

  const handleSetTabValue = (event, newValue) => {
    switch (tabs[newValue]) {
      case t('customer-info'):
        setCurrentTabView(t('customer-info'));
        break;
      case t('attorney'):
        setCurrentTabView(t('attorney'));
        break;
      case t('tags'):
        setCurrentTabView(t('tags'));
        break;
      default:
        setCurrentTabView(t('customer-info'));
        break;
    }
  };

  const setValue = (key, value) => {
    dispatch(
      setCustomerInfo(
        {
          ...customerInfo,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleCountry = (e) => {
    if (e.id !== undefined) {
      dispatch(
        fetchCities({
          countryId: 233,
          stateId: e.id,
        }),
      );

      setValue('state_id', e.value);
    }
  };

  return (
    <div id="customer">
      <TabBar
        tabs={tabs}
        setTabValueFunction={handleSetTabValue}
        defaultTabValue={0}
      />
      {currentTabView === t('customer-info') && (
        <Card id="customer-info" sx={{ overflow: 'visible' }}>
          <SuiBox p={3}>
            <SuiTypography variant="h5">{t('customer-info')}</SuiTypography>
          </SuiBox>
          <SuiBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FormField
                  label={t('name')}
                  value={customerInfo.name !== undefined ? customerInfo.name : ''}
                  error={!customerInfo.name || customerInfo.name === ''}
                  onChange={(e) => {
                    setValue('name', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FormField
                  label={t('website')}
                  value={customerInfo.website !== undefined ? customerInfo.website : ''}
                  onChange={(e) => {
                    setValue('website', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <FormField
                  label={t('address')}
                  value={customerInfo.address !== undefined ? customerInfo.address : ''}
                  onChange={(e) => {
                    setValue('address', e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <FormSelect
                  label={t('state')}
                  placeholder={t('state')}
                  options={states}
                  value={
                    states !== undefined
                      ? findSelectValue(states, customerInfo.state_id) : undefined
                  }
                  error={customerInfo.state_id ? false
                    : !customerInfo.state_id || customerInfo.state_id === 0}
                  onChange={handleCountry}
                />
              </Grid>
              <Grid item md={4}>
                <FormSelect
                  label={t('city')}
                  options={cities}
                  value={
                    cities !== undefined
                      ? findSelectValue(cities, customerInfo.city_id) : undefined
                  }
                  error={customerInfo.city_id ? false
                    : !customerInfo.city_id || customerInfo.city_id === 0}
                  onChange={(e) => {
                    setValue('city_id', e.value);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      )}
      {currentTabView === t('attorney') && (
        <Card sx={{ overflow: 'visible' }}>
          <CardHeader
            title={t('attorney')}
          />
          <SuiBox p={1} mb={2}>
            <Grid container spacing={3}>
              <Grid item md={12} pr={1}>
                <FormSelect
                  label={t('attorney')}
                  options={attorneys}
                  value={
                    findSelectValue(attorneys, customerInfo.attorney_id)
                  }
                  onChange={(event) => {
                    setValue('attorney_id', event.value);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      )}
      {currentTabView === t('tags') && (
        <Card sx={{ overflow: 'visible' }}>
          <CardHeader
            title={t('tags')}
          />
          <SuiBox p={1} mb={2}>
            <Grid container spacing={3}>
              <Grid item md={12} pr={1}>
                <SuiTagInput
                  placeholder={t('add-new-tag')}
                  size="large"
                  tags={customerInfo.recruitment_list !== undefined
                    ? customerInfo.recruitment_list : []}
                  onChange={(event) => {
                    setValue('recruitment_list', event);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      )}
    </div>
  );
}

export default CustomerInfo;
