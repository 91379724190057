import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// Reducer functions
import {
  assignCandidateImmigrationDocument,
  deleteCandidateImmigrationDocument,
  fetchCandidateImmigration,
  setImmigration,
  uploadCandidateImmigrationDocument,
} from 'reducers/candidatesSlice';
import { fetchDocuments } from 'reducers/documentsSlice';
import { fetchVisaScreenAgencies } from 'reducers/commonSlice';

// @mui material components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// Functions
import {
  checkPermission,
  findSelectValue,
  getCandidateUUID,
  setTitle,
} from 'Util';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

require('datejs');

const selector = (state) => ({
  editing: state.candidate.editing,
  currentUser: state.auth.user,
  immigration: state.candidate.immigration,
  agencies: state.common.visaScreenAgencies,
  documents: state.document.documents,
});

function Immigration() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.immigration',
  });

  const [documentMenu, setDocumentMenu] = useState(null);
  const [expandedDocumentState, setExpandedDocumentState] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availDocs, setAvailDocs] = useState([]);
  const [assignedDocs, setAssignedDocs] = useState([]);

  const {
    editing,
    currentUser,
    immigration,
    agencies,
    documents,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchVisaScreenAgencies());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateImmigration({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (immigration.documents !== undefined) {
      setAvailDocs(documents
        .filter((doc) => doc.document_type_id === 12)
        .filter((ad) => !immigration.documents.some((cd) => ad.id === cd.document.id)));
      setAssignedDocs(immigration.documents);
    }
  }, [documents, immigration]);

  setTitle('Candidate Immigration');

  const visaTypes = [
    {
      value: 1,
      label: 'EB-3',
    },
    {
      value: 2,
      label: 'HB-1',
    },
  ];

  const visaStatus = [
    {
      value: 1,
      label: t('approved'),
    },
    {
      value: 2,
      label: t('denied'),
    },
    {
      value: 3,
      label: t('review'),
    },
  ];

  const currentUsOptions = [
    {
      value: 'Yes',
      label: t('yes', { keyPrefix: 'common' }),
    },
    {
      value: 'No',
      label: t('no', { keyPrefix: 'common' }),
    },
  ];

  const currentImmigrationStatus = [
    {
      value: 1,
      label: t('visa'),
    },
    {
      value: 2,
      label: t('asylum'),
    },
    {
      value: 3,
      label: t('no-status'),
    },
  ];

  const visaCategories = [
    {
      value: 'A-1',
      label: 'A-1',
    },
    {
      value: 'A-2',
      label: 'A-2',
    },
    {
      value: 'B-1',
      label: 'B-1',
    },
    {
      value: 'B-2',
      label: 'B-2',
    },
    {
      value: 'BCC',
      label: 'BCC',
    },
    {
      value: 'C',
      label: 'C',
    },
    {
      value: 'CW-1',
      label: 'CW-1',
    },
    {
      value: 'CW-2',
      label: 'CW-2',
    },
    {
      value: 'D',
      label: 'D',
    },
    {
      value: 'E-1',
      label: 'E-1',
    },
    {
      value: 'E-2',
      label: 'E-2',
    },
    {
      value: 'E-3',
      label: 'E-3',
    },
    {
      value: 'F',
      label: 'F',
    },
    {
      value: 'G-1',
      label: 'G-1',
    },
    {
      value: 'G-2',
      label: 'G-2',
    },
    {
      value: 'G-3',
      label: 'G-3',
    },
    {
      value: 'G-4',
      label: 'G-4',
    },
    {
      value: 'G-5',
      label: 'G-5',
    },
    {
      value: 'H-1B',
      label: 'H-1B',
    },
    {
      value: 'H-1B1',
      label: 'H-1B1',
    },
    {
      value: 'H-2A',
      label: 'H-2A',
    },
    {
      value: 'H-2B',
      label: 'H-2B',
    },
    {
      value: 'H-3',
      label: 'H-3',
    },
    {
      value: 'I',
      label: 'I',
    },
    {
      value: 'J-1',
      label: 'J-1',
    },
    {
      value: 'J-2',
      label: 'J-2',
    },
    {
      value: 'L',
      label: 'L',
    },
    {
      value: 'M',
      label: 'M',
    },
    {
      value: 'NATO-1',
      label: 'NATO-1',
    },
    {
      value: 'NATO-2',
      label: 'NATO-2',
    },
    {
      value: 'NATO-3',
      label: 'NATO-3',
    },
    {
      value: 'NATO-4',
      label: 'NATO-4',
    },
    {
      value: 'NATO-5',
      label: 'NATO-5',
    },
    {
      value: 'NATO-6',
      label: 'NATO-6',
    },
    {
      value: 'O',
      label: 'O',
    },
    {
      value: 'P-1',
      label: 'P-1',
    },
    {
      value: 'P-2',
      label: 'P-2',
    },
    {
      value: 'P-3',
      label: 'P-3',
    },
    {
      value: 'Q-1',
      label: 'Q-1',
    },
    {
      value: 'R',
      label: 'R',
    },
    {
      value: 'TD',
      label: 'TD',
    },
    {
      value: 'TN',
      label: 'TN',
    },
    {
      value: 'T-1',
      label: 'T-1',
    },
    {
      value: 'T-2',
      label: 'T-2',
    },
    {
      value: 'T-3',
      label: 'T-3',
    },
    {
      value: 'T-4',
      label: 'T-4',
    },
    {
      value: 'T-5',
      label: 'T-5',
    },
    {
      value: 'T-6',
      label: 'T-6',
    },
    {
      value: 'U-1',
      label: 'U-1',
    },
    {
      value: 'V',
      label: 'V',
    },
  ];

  const yesNoStatus = [
    {
      value: true,
      label: t('yes', { keyPrefix: 'common' }),
    },
    {
      value: false,
      label: t('no', { keyPrefix: 'common' }),
    },
  ];

  const openDocumentMenu = (event) => setDocumentMenu(event.currentTarget);
  const closeDocumentMenu = () => setDocumentMenu(null);

  const setValue = (key, value) => {
    dispatch(
      setImmigration({
        ...immigration,
        [key]: value,
        changed: true,
      }),
    );
  };

  const setApplicationStart = (value) => {
    const expiration = Date.parse(`${value}T00:00:00`);
    expiration.add(12).months();
    dispatch(
      setImmigration({
        ...immigration,
        visa_screen_application_start: value,
        visa_screen_application_expiration: expiration.toString('yyyy-MM-dd'),
        changed: true,
      }),
    );
  };

  const setVisaScreenIssue = (value) => {
    const expiration = Date.parse(`${value}T00:00:00`);
    expiration.add(60).months();
    dispatch(
      setImmigration({
        ...immigration,
        visa_screen_issue: value,
        visa_screen_expiration: expiration.toString('yyyy-MM-dd'),
        changed: true,
      }),
    );
  };

  const handleAddDocument = () => {
    dispatch(assignCandidateImmigrationDocument({
      candidateId: uuid,
      immigrationId: immigration.id,
      documentId: selectedDoc,
    }));
    setSelectedDoc(null);
  };

  const handleDeleteDocument = (id) => {
    dispatch(deleteCandidateImmigrationDocument({
      candidateId: uuid,
      immigrationId: immigration.id,
      documentId: id,
    }));
  };

  const handleUploadDocument = (files, id) => {
    dispatch(uploadCandidateImmigrationDocument({
      candidateId: uuid,
      immigrationId: immigration.id,
      documentId: id,
      file: files[0],
    }));
  };

  const handleDocumentExpand = () => {
    closeDocumentMenu();
    setExpandedDocumentState(!expandedDocumentState);
  };

  function calculateAge(date) {
    const dob = new Date(date);

    if (!(dob instanceof Date)) {
      throw new Error('Invalid date of birth. Please provide a Date object.');
    }

    const today = new Date();
    let ageYears = today.getFullYear() - dob.getFullYear();
    let ageMonths = today.getMonth() - dob.getMonth();

    if (ageMonths < 0) {
      ageYears -= 1;
      ageMonths += 12;
    }

    if (today.getDate() < dob.getDate()) {
      ageMonths -= 1;
      if (ageMonths < 0) {
        ageYears -= 1;
        ageMonths += 12;
      }
    }

    return `${t('age')}: ${ageYears} ${t('years')} ${t('and')} ${ageMonths} ${t('months')}`;
  }

  const setChildrenValue = (key, value, i) => {
    dispatch(
      setImmigration({
        ...immigration,
        children: immigration.children.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
            };
          }
          return obj;
        }),
        changed: true,
      }),
    );
  };

  const handleAddChild = () => {
    const children = immigration.children === undefined ? [] : immigration.children;
    dispatch(
      setImmigration({
        ...immigration,
        children: children.concat({
          id: null,
          dob: Date(),
        }),
        changed: true,
      }),
    );
  };

  const handleRemoveChild = (i) => {
    if (immigration.children[i].id !== undefined) {
      dispatch(
        setImmigration({
          ...immigration,
          children: immigration.children.map((obj, index) => {
            if (index === i) {
              return {
                ...obj,
                _destroy: true,
              };
            }
            return obj;
          }),
          changed: true,
        }),
      );
    } else {
      dispatch(
        setImmigration({
          ...immigration,
          children: [...immigration.children.slice(0, i), ...immigration.children.slice(i + 1)],
          changed: true,
        }),
      );
    }
  };

  return (
    <SuiBox p={1}>
      <Card id="immigration-info" sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('immigration-info')}
        />
        <SuiBox p={1} mb={2}>
          <Grid container spacing={1}>
            {checkPermission('CRII', currentUser) && (
              <>
                <Grid item md={2}>
                  <FormSelect
                    label={t('currently-in-us')}
                    textTransform="none"
                    options={currentUsOptions}
                    value={findSelectValue(currentUsOptions, immigration.currently_in_us)}
                    onChange={(e) => {
                      setValue('currently_in_us', e.value);
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  {immigration.currently_in_us === 'Yes' && (
                    <FormSelect
                      label={t('current-immigration-status')}
                      textTransform="none"
                      options={currentImmigrationStatus}
                      value={findSelectValue(
                        currentImmigrationStatus,
                        immigration.current_immigration_status,
                      )}
                      onChange={(e) => {
                        setValue('current_immigration_status', e.value);
                      }}
                    />
                  )}
                </Grid>
                <Grid item md={3}>
                  {(immigration.currently_in_us === 'Yes' && immigration.current_immigration_status) === 1
                    && (
                      <FormSelect
                        label={t('visa-category')}
                        textTransform="none"
                        options={visaCategories}
                        value={findSelectValue(visaCategories, immigration.visa_category)}
                        onChange={(e) => {
                          setValue('visa_category', e.value);
                        }}
                      />
                    )}
                  {(immigration.currently_in_us === 'Yes' && immigration.current_immigration_status === 2)
                    && (
                      <FormSelect
                        label={t('asylum-approved')}
                        textTransform="none"
                        options={yesNoStatus}
                        value={findSelectValue(yesNoStatus, immigration.asylum_approved)}
                        onChange={(e) => {
                          setValue('asylum_approved', e.value);
                        }}
                      />
                    )}
                </Grid>
                <Grid item md={2}>
                  {immigration.currently_in_us === 'Yes' && (
                    <FormSelect
                      label={t('issued-ead')}
                      textTransform="none"
                      options={yesNoStatus}
                      value={findSelectValue(yesNoStatus, immigration.issued_ead)}
                      onChange={(e) => {
                        setValue('issued_ead', e.value);
                      }}
                    />
                  )}
                </Grid>
                <Grid item md={2}>
                  {immigration.currently_in_us === 'Yes' && (
                    <FormSelect
                      label={t('issued-ssn')}
                      textTransform="none"
                      options={yesNoStatus}
                      value={findSelectValue(yesNoStatus, immigration.issued_ssn)}
                      onChange={(e) => {
                        setValue('issued_ssn', e.value);
                      }}
                    />
                  )}
                </Grid>
                <Grid item md={1}>
                  <FormSelect
                    label={t('visa-type')}
                    options={visaTypes}
                    value={
                      findSelectValue(visaTypes, immigration.visa_type_id)
                    }
                    onChange={(event) => {
                      setValue('visa_type_id', event.value);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormField
                    label={t('i140-submission-date')}
                    type="date"
                    value={immigration.i140_submission}
                    onChange={(e) => {
                      setValue('i140_submission', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormField
                    label={t('i140-approval-date')}
                    type="date"
                    value={immigration.i140_approval}
                    onChange={(e) => {
                      setValue('i140_approval', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormField
                    label={t('priority-date')}
                    type="date"
                    value={immigration.priority_date}
                    onChange={(e) => {
                      setValue('priority_date', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormField
                    label={t('embassy-appointment')}
                    type="date"
                    value={immigration.embassy_appointment}
                    onChange={(e) => {
                      setValue('embassy_appointment', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <FormSelect
                    label={t('visa-status')}
                    options={visaStatus}
                    value={
                      findSelectValue(visaStatus, immigration.visa_status_id)
                    }
                    onChange={(e) => {
                      setValue('visa_status_id', e.value);
                    }}
                  />
                </Grid>
              </>
            )}
            {checkPermission('CAII', currentUser) && (
              <>
                <Grid item md={4}>
                  <FormSelect
                    label={t('immigration-issues')}
                    textTransform="none"
                    options={yesNoStatus}
                    value={findSelectValue(yesNoStatus, immigration.immigration_issues)}
                    onChange={(e) => {
                      setValue('immigration_issues', e.value);
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  {immigration.immigration_issues && (
                    <div className="document-editor">
                      <div className="document-editor__toolbar" />
                      <CKEditor
                        editor={DecoupledEditor}
                        data={immigration.immigration_issues_detail}
                        onReady={(editor) => {
                          window.editor = editor;
                          if (document.querySelector('.document-editor__toolbar') !== undefined
                            && document.querySelector('.document-editor__toolbar') !== null) {
                            const toolbarContainer = document.querySelector('.document-editor__toolbar');
                            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                          }
                        }}
                        onChange={(event, editor) => {
                          if (immigration.immigration_issues_detail !== undefined) {
                            setValue('immigration_issues_detail', editor.getData());
                          }
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </SuiBox>
      </Card>
      <Divider />
      {checkPermission('CAFI', currentUser) && (
        <>
          <Card id="family-info" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('family-info')}
            />
            <SuiBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item md={2}>
                  <FormSelect
                    label={t('legally-married')}
                    textTransform="none"
                    options={currentUsOptions}
                    value={findSelectValue(currentUsOptions, immigration.legally_married)}
                    onChange={(e) => {
                      setValue('legally_married', e.value);
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Grid
                    container
                    direction="row"
                    sx={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item>
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={handleAddChild}
                      >
                        {t('add-child')}
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
                {(immigration.children !== undefined && immigration.children !== null)
                  // eslint-disable-next-line no-underscore-dangle
                  && immigration.children.filter((i) => i._destroy !== true).map((c, i) => (
                    <>
                      <Grid item md={3} mt={4}>
                        <SuiTypography>{`${t('child')} ${i + 1}`}</SuiTypography>
                      </Grid>
                      <Grid item md={2}>
                        <FormField
                          label={t('dob')}
                          type="date"
                          value={c.dob}
                          onChange={(e) => {
                            setChildrenValue('dob', e.target.value, i);
                          }}
                        />
                      </Grid>
                      <Grid item md={1} mt={5}>
                        <CancelOutlinedIcon
                          color="error"
                          onClick={() => {
                            handleRemoveChild(i);
                          }}
                        />
                      </Grid>
                      <Grid item md={4} mt={4}>
                        <SuiTypography>
                          {calculateAge(c.dob)}
                        </SuiTypography>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
      {checkPermission('CAVS', currentUser) && (
        <>
          <Card id="visa-screen" sx={{ overflow: 'visible' }}>
            <CardHeader
              title={t('visa-screen')}
            />
            <SuiBox p={1} mb={2}>
              <Grid container spacing={3}>
                <Grid item md={3} pr={1}>
                  <FormSelect
                    label={t('agency-name')}
                    options={agencies}
                    value={
                      findSelectValue(agencies, immigration.visa_screen_agency_id)
                    }
                    onChange={(event) => {
                      setValue('visa_screen_agency_id', event.value);
                    }}
                  />
                </Grid>
                <Grid item md={3} pr={1}>
                  <FormField
                    label={t('application-start-date')}
                    type="date"
                    value={immigration.visa_screen_application_start}
                    onChange={(e) => {
                      setApplicationStart(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={3} pr={1}>
                  <FormField
                    label={t('application-expiration-date')}
                    type="date"
                    value={immigration.visa_screen_application_expiration}
                    onChange={(e) => {
                      setValue('visa_screen_application_expiration', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={3} />
                <Grid item md={3} pr={1}>
                  <FormField
                    label={t('issue-date')}
                    type="date"
                    value={immigration.visa_screen_issue}
                    onChange={(e) => {
                      setVisaScreenIssue(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={3} pr={1}>
                  <FormField
                    label={t('expiration-date')}
                    type="date"
                    value={immigration.visa_screen_expiration}
                    onChange={(e) => {
                      setValue('visa_screen_expiration', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Card sx={{ overflow: 'visible' }}>
                    <CardHeader
                      action={(
                        <IconButton onClick={openDocumentMenu}>
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      title={t('result-document')}
                    />
                    <Menu
                      anchorEl={documentMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(documentMenu)}
                      onClose={closeDocumentMenu}
                      keepMounted
                    >
                      <MenuItem
                        onClick={handleDocumentExpand}
                      >
                        {expandedDocumentState
                          ? t('collapse', { keyPrefix: 'common' })
                          : t('expand', { keyPrefix: 'common' })}
                      </MenuItem>
                    </Menu>
                    <Collapse in={expandedDocumentState} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Grid container spacing={3}>
                          {Object.keys(availDocs).length > 0 && (
                            <>
                              <Grid item md={3} pr={1}>
                                <FormSelect
                                  label={t('assign-document')}
                                  placeholder={t('select-document')}
                                  options={availDocs}
                                  onChange={(ev) => {
                                    setSelectedDoc(ev.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3} pr={1} mt={5}>
                                <IconButton
                                  aria-label="add"
                                  onClick={handleAddDocument}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                              <Grid item md={6} pr={1} />
                            </>
                          )}
                          {Object.keys(assignedDocs).length > 0
                            && assignedDocs.map((f, j) => (
                              <Grid item md={12} pr={1}>
                                <DefaultDocumentCard
                                  key={`evaluation-document-${j}`}
                                  id={f.id}
                                  candidateId={uuid}
                                  description={f.document.description}
                                  title={f.document.name}
                                  fileUrl={f.file_url}
                                  filesLimit={f.document.uploads_number}
                                  changeFunc={(files) => {
                                    handleUploadDocument(files, f.id);
                                  }}
                                  removeFunc={() => {
                                    handleDeleteDocument(f.id);
                                  }}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
          <Divider />
        </>
      )}
    </SuiBox>
  );
}

export default Immigration;
