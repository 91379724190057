import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  assignCandidateRecruitmentDocument, deleteCandidateRecruitmentDocument,
  fetchCandidateRecruitment,
  setFailed,
  setSaved,
  updateCandidateRecruitment, uploadCandidateRecruitmentDocument,
} from 'reducers/candidatesSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// @material-ui core components
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import OutlineTable from 'layouts/components/Cards/PositionCards/components/OutlineTable';
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import ApplicationPositionCard
  from 'layouts/components/Cards/PositionCards/ApplicationPositionCard';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';
import DefaultModal from 'layouts/components/Modal/DefaultModal';

// Functions
import {
  defaultValue,
  failedCandidateMessage,
  findSelectValue,
  getCandidateUUID,
  savedCandidateMessage,
} from 'Util';

const selector = (state) => ({
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  recruitments: state.candidate.recruitments,
  documents: state.document.documents,
});

function JobApplications() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.job-applications' });
  const {
    saved,
    failed,
    errors,
    recruitments,
    documents,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [openModal, setOpenModal] = useState(false);
  const [position, setPosition] = useState({});
  const [recruitment, setRecruitment] = useState({});
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availDocs, setAvailDocs] = useState([]);
  const [assignedDocs, setAssignedDocs] = useState([]);

  const stages = [
    {
      value: 1,
      label: t('sourced'),
    },
    {
      value: 2,
      label: t('screening'),
    },
    {
      value: 3,
      label: t('interview'),
    },
    {
      value: 4,
      label: t('offered'),
    },
    {
      value: 5,
      label: t('hired'),
    },
    {
      value: 6,
      label: t('rejected'),
    },
  ];

  const offerReceived = [
    {
      value: false,
      label: t('no', { keyPrefix: 'common' }),
    },
    {
      value: true,
      label: t('yes', { keyPrefix: 'common' }),
    },
  ];

  const offerStatuses = [
    {
      value: 1,
      label: t('pending'),
    },
    {
      value: 2,
      label: t('rejected'),
    },
    {
      value: 3,
      label: t('accepted'),
    },
  ];

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchCandidateRecruitment({ uuid }));
  }, [dispatch, uuid]);

  useEffect(() => {
    if (recruitment.documents !== undefined) {
      setAvailDocs(documents
        .filter((doc) => doc.document_type_id === 13)
        .filter((ad) => !recruitment.documents.some((cd) => ad.id === cd.document.id)));
      setAssignedDocs(recruitment.documents);
    }
  }, [documents, recruitment]);

  useEffect(() => {
    if (recruitment.id !== undefined) {
      setRecruitment(recruitments
        .find((obj) => obj.id === recruitment.id));
    }
  }, [recruitment.id, recruitments]);

  const setValue = (key, value) => {
    setRecruitment({
      ...recruitment,
      [key]: value,
      changed: true,
    });
  };

  const handleOpenModal = (e, id) => {
    e.preventDefault();
    const pos = recruitments.find((p) => p.id === id);
    setPosition(pos.position_listing);
    setRecruitment(pos);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setRecruitment({});
    setOpenModal(false);
  };

  const handleSave = () => {
    if (recruitment.id !== undefined) {
      dispatch(updateCandidateRecruitment({
        id: recruitment.id,
        candidateId: uuid,
        positionListingId: recruitment.position_listing_id,
        recruitmentStageId: recruitment.recruitment_stage_id,
        startDate: recruitment.start_date,
        customerInfoSentOn: recruitment.customer_info_sent_on,
        sentToCustomerOn: recruitment.sent_to_customer_on,
        preparationEmail: recruitment.preparation_email,
        preparationInterview: recruitment.preparation_interview,
        interviewDate: recruitment.interview_date,
        offerReceived: recruitment.offer_received,
        offerReceivedDate: recruitment.offer_received_date,
        offerStatusId: recruitment.offer_status_id,
        offerAcceptanceDate: recruitment.offer_acceptance_date,
      }));
      handleCloseModal();
    }
  };

  const handleAddDocument = () => {
    if (recruitment !== undefined) {
      dispatch(assignCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: selectedDoc,
      }));
      setSelectedDoc(null);
    }
  };

  const handleDeleteDocument = (id) => {
    if (recruitment !== undefined) {
      dispatch(deleteCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: id,
      }));
    }
  };

  const handleUploadDocument = (files, id) => {
    if (recruitment !== undefined) {
      dispatch(uploadCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: id,
        file: files[0],
      }));
    }
  };

  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SuiBox p={2}>
      {Object.keys(recruitments).length > 0
        && recruitments.map((a, i) => (
          <div key={`application${i}`}>
            <ApplicationPositionCard
              position={a}
              openFunction={(e) => handleOpenModal(e, a.id)}
            />
            <Divider />
          </div>
        ))}
      <DefaultModal openModal={openModal} closeFunc={() => setOpenModal(false)}>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
          spacing={1}
        >
          <Grid item md={12}>
            <SuiTypography variant="h2">{position.name}</SuiTypography>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={3}>
            <OutlineTable
              dateOpened={position.date_opened}
              state={position.state}
              city={position.city}
              tags={position.tags}
            />
          </Grid>
          <Grid item md={9}>
            <Grid
              container
              spacing={1}
            >
              <Grid item md={12}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <FormSelect
                      label={t('stage')}
                      options={stages}
                      value={
                        findSelectValue(stages, recruitment.recruitment_stage_id)
                      }
                      onChange={(e) => {
                        setValue('recruitment_stage_id', e.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {recruitment.recruitment_stage_id === 1 && (
                <>
                  <Grid item md={3}>
                    <FormField
                      label={t('application-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('start_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.start_date)}
                    />
                  </Grid>
                  <Grid item md={9} />
                  <Grid item md={3}>
                    <FormField
                      label={t('customer-info-sent-on')}
                      type="date"
                      onChange={(event) => {
                        setValue('customer_info_sent_on', event.target.value);
                      }}
                      value={defaultValue(recruitment.customer_info_sent_on)}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SuiButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-info-to-candidate')}
                    </SuiButton>
                  </Grid>
                </>
              )}
              {recruitment.recruitment_stage_id === 2 && (
                <>
                  <Grid item md={3}>
                    <FormField
                      label={t('sent-to-customer')}
                      type="date"
                      onChange={(event) => {
                        setValue('sent_to_customer_on', event.target.value);
                      }}
                      value={defaultValue(recruitment.sent_to_customer_on)}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SuiButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-to-customer')}
                    </SuiButton>
                  </Grid>
                </>
              )}
              {recruitment.recruitment_stage_id === 3 && (
                <>
                  <Grid item md={3}>
                    <FormField
                      label={t('preparation-email')}
                      type="date"
                      onChange={(event) => {
                        setValue('preparation_email', event.target.value);
                      }}
                      value={defaultValue(recruitment.preparation_email)}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SuiButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-preparation-email')}
                    </SuiButton>
                  </Grid>
                  <Grid item md={4} />
                  <Grid item md={3}>
                    <FormField
                      label={t('preparation-interview')}
                      type="date"
                      onChange={(event) => {
                        setValue('preparation_interview', event.target.value);
                      }}
                      value={defaultValue(recruitment.preparation_interview)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormField
                      label={t('interview-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('interview_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.interview_date)}
                    />
                  </Grid>
                </>
              )}
              {recruitment.recruitment_stage_id === 4 && (
                <>
                  <Grid item md={3}>
                    <FormSelect
                      label={t('offer-received')}
                      placeholder={t('offer-received')}
                      options={offerReceived}
                      value={
                        findSelectValue(offerReceived, recruitment.offer_received)
                      }
                      onChange={(event) => {
                        setValue('offer_received', event.value);
                      }}
                    />
                  </Grid>
                  {recruitment.offer_received && (
                    <>
                      <Grid item md={3}>
                        <FormField
                          label={t('offer-received-date')}
                          type="date"
                          onChange={(event) => {
                            setValue('offer_received_date', event.target.value);
                          }}
                          value={defaultValue(recruitment.offer_received_date)}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FormSelect
                          label={t('offer-status')}
                          placeholder={t('offer-status')}
                          options={offerStatuses}
                          value={
                            findSelectValue(offerStatuses, recruitment.offer_status_id)
                          }
                          onChange={(event) => {
                            setValue('offer_status_id', event.value);
                          }}
                        />
                      </Grid>
                      {recruitment.offer_status_id === 3 && (
                        <>
                          <Grid item md={3}>
                            <FormField
                              label={t('offer-acceptance-date')}
                              type="date"
                              onChange={(event) => {
                                setValue('offer_acceptance_date', event.target.value);
                              }}
                              value={defaultValue(recruitment.offer_acceptance_date)}
                            />
                          </Grid>
                          {Object.keys(availDocs).length > 0 && (
                            <>
                              <Grid item md={4} pr={1}>
                                <FormSelect
                                  label={t('assign-document')}
                                  placeholder={t('select-document')}
                                  options={availDocs}
                                  onChange={(e) => {
                                    setSelectedDoc(e.value);
                                  }}
                                />
                              </Grid>
                              <Grid item md={3} pr={1} mt={5}>
                                <IconButton
                                  aria-label="add"
                                  onClick={handleAddDocument}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                            </>
                          )}
                          {Object.keys(assignedDocs).length > 0
                            && assignedDocs.map((f, j) => (
                              <Grid item md={12} pr={1}>
                                <DefaultDocumentCard
                                  key={`evaluation-document-${j}`}
                                  id={f.id}
                                  candidateId={uuid}
                                  description={f.document.description}
                                  title={f.document.name}
                                  expanded={false}
                                  fileUrl={f.file_url}
                                  filesLimit={f.document.uploads_number}
                                  changeFunc={(files) => {
                                    handleUploadDocument(files, f.id);
                                  }}
                                  removeFunc={() => {
                                    handleDeleteDocument(f.id);
                                  }}
                                />
                              </Grid>
                            ))}
                          <Grid item md={6} pr={1} />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <Grid item md={12}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <SuiButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleSave}
                    >
                      {t('save', { keyPrefix: 'common' })}
                    </SuiButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DefaultModal>
    </SuiBox>
  );
}

export default JobApplications;
