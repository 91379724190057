import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createCandidateAgreement,
  deleteCandidateAgreement,
  fetchCandidateAgreements,
  fetchCandidatePersonal,
  fetchCandidateProfile,
  fetchCandidateTemplateVariables,
  setCurrentSubView,
  setFailed,
  setSaved,
  signCandidateAgreement,
  updateCandidateAgreement,
  updateCandidateAgreementDocument,
} from 'reducers/candidatesSlice';
import { fetchAgreements } from 'reducers/agreementsSlice';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CardActions, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';

// Functions
import {
  checkPermission,
  findSelectValue,
  findTemplateVariables,
  getCandidateUUID,
  MissingDataWarning,
  numToWords,
  setTitle,
} from 'Util';
import Swal from 'sweetalert2';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import DefaultAgreementCard from 'layouts/components/Cards/AgreementCards/DefaultAgreementCard';
import AgreementInfo from './components/AgreementInfo';

const cardStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgColor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '80%',
  display: 'block',
};

const selector = (state) => ({
  currentUser: state.auth.user,
  candidateAgreements: state.candidate.agreements,
  agreements: state.agreement.agreements,
  candidateProfile: state.candidate.profile,
  personal: state.candidate.personal,
  templateVars: state.candidate.templateVars,
  editing: state.candidate.editing,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});

function Agreements() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.agreements',
  });
  const {
    currentUser,
    candidateAgreements,
    agreements,
    candidateProfile,
    personal,
    templateVars,
    editing,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [templateVariables, setTemplateVariables] = useState([]);
  const [variablesValues, setVariablesValues] = useState([]);
  const [availableAgreement, setAvailableAgreement] = useState(undefined);
  const [availableAgreements, setAvailableAgreements] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [signedAgreements, setSignedAgreements] = useState([]);
  const [unsignedAgreements, setUnsignedAgreements] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(
    {
      id: 0,
      agreement: { name: '' },
      file_url: '',
    },
  );
  const [showAgreement, setShowAgreement] = useState(false);

  useEffect(() => {
    dispatch(setCurrentSubView('agreements'));
    dispatch(fetchAgreements());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
      dispatch(fetchCandidateAgreements({ uuid }));
      dispatch(fetchCandidateTemplateVariables({ uuid }));
      dispatch(fetchCandidatePersonal({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (candidateAgreements !== undefined) {
      setSignedAgreements(candidateAgreements.filter((a) => a.signed === true));
      setUnsignedAgreements(candidateAgreements.filter((a) => a.signed !== true));

      if (selectedAgreement.id !== 0) {
        setSelectedAgreement(candidateAgreements.filter((a) => a.id === selectedAgreement.id)[0]);
      }
    }
    // eslint-disable-next-line
  }, [candidateAgreements]);

  useEffect(() => {
    setAvailableAgreements(agreements
      .filter((aa) => !candidateAgreements.some((cd) => aa.id === cd.agreement.id)));
  }, [agreements, candidateAgreements]);

  setTitle('Candidate Agreements');

  const toggleDrawer = (open) => () => {
    setShowAgreement(open);

    if (!open) {
      setSelectedAgreement({
        id: 0,
        agreement: { name: '' },
        file_url: '',
      });
    }
  };

  const handleOpen = () => setOpenAssign(true);

  const handleClose = () => setOpenAssign(false);

  const handleAssign = () => {
    if (availableAgreement !== undefined) {
      let isValid = true;

      templateVariables.forEach((v) => {
        const name = v.replace('<%= ', '').replace(' %>', '');
        isValid = isValid
          ? variablesValues[name] !== undefined && variablesValues[name] !== ''
          : false;
      });

      if (isValid) {
        dispatch(
          createCandidateAgreement({
            id: availableAgreement.id,
            candidateId: candidateProfile.uuid,
            parameters: JSON.stringify({ variables: variablesValues }),
          }),
        );

        handleClose();
      } else {
        MissingDataWarning(t);
      }
    }
  };

  const handleValidateAgreement = () => {
    if (availableAgreement !== undefined) {
      // const agreement = agreements.find((a) => a.id === availableAgreement.id);
      const vars = findTemplateVariables(availableAgreement.content);

      setTemplateVariables(vars);

      const varVals = {};

      vars.forEach((v) => {
        const name = v.replace('<%= ', '').replace(' %>', '');
        varVals[name] = templateVars[name] !== undefined ? templateVars[name] : '';
      });

      setVariablesValues(varVals);

      handleOpen();
    }
  };

  const handleSign = (agreementId) => {
    if (
      personal.signature_url !== undefined
      && personal.signature_url !== null
    ) {
      dispatch(
        signCandidateAgreement({
          id: agreementId,
          candidateId: uuid,
        }),
      );
    } else {
      Swal.fire({
        text: t('signature-undefined-or-invalid'),
        icon: 'error',
        confirmButtonText: t('close', { keyPrefix: 'common' }),
      });
    }
  };

  const handleModify = (agreementId, modified) => {
    const a = candidateAgreements.find((ca) => ca.id === agreementId);

    if (a !== undefined) {
      if (Object.keys(modified).length > 0) {
        const data = modified;
        data.id = a.id;
        data.candidateId = uuid;

        dispatch(updateCandidateAgreement(data));
      }
    }
  };

  const handleRemove = (agreementId) => {
    Swal.fire({
      text: t('are-you-sure-you-want-to-delete-it', {
        keyPrefix: 'common',
      }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then((result) => {
      if (result.isConfirmed) {
        setShowAgreement(false);
        setSelectedAgreement({
          id: 0,
          agreement: { name: '' },
          file_url: '',
        });

        dispatch(
          deleteCandidateAgreement({
            id: agreementId,
            candidateId: uuid,
          }),
        );
      }
    });
  };

  const handleUploadFile = (id, files) => {
    dispatch(
      updateCandidateAgreementDocument({
        id,
        candidateId: uuid,
        files: files[0],
      }),
    );
  };

  const handleSelectAgreement = (id) => {
    setSelectedAgreement(candidateAgreements.filter((a) => a.id === id)[0]);
    setShowAgreement(true);
  };

  const setValue = (key, value) => {
    if (Object.prototype.hasOwnProperty.call(variablesValues, `${key}_text`)) {
      setVariablesValues({
        ...variablesValues,
        [`${key}_text`]: numToWords(availableAgreement.language, value),
        [key]: value,
      });
    } else {
      setVariablesValues({
        ...variablesValues,
        [key]: value,
      });
    }
  };

  if (saved) {
    dispatch(setSaved(false));

    Swal.fire({
      title: `${t('success', { keyPrefix: 'candidates.save' })}!`,
      text: t('candidate-saved-successfully', { keyPrefix: 'candidates.save' }),
      icon: 'success',
      confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
    });
  }
  if (failed) {
    dispatch(setFailed(false));

    Swal.fire({
      title: t('error', { keyPrefix: 'candidates.save' }),
      text: errors,
      icon: 'error',
      confirmButtonText: t('close', { keyPrefix: 'candidates.save' }),
    });
  }

  return (
    <div>
      {checkPermission('CAPS', currentUser) && editing && (
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={5} pr={1}>
            <FormSelect
              label={t('available-agreements')}
              options={availableAgreements}
              onChange={(e) => {
                setAvailableAgreement(findSelectValue(agreements, e.value));
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} pr={1} mt={5}>
            <IconButton
              aria-label="add"
              onClick={handleValidateAgreement}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item md={6}>
          <SuiTypography variant="h4">
            {`${t('awaiting-signature')} (${Object.keys(unsignedAgreements).length})`}
          </SuiTypography>
        </Grid>
        <Grid item md={6}>
          <SuiTypography variant="h4">
            {`${t('signed')} (${Object.keys(signedAgreements).length})`}
          </SuiTypography>
        </Grid>
        <Grid item md={6}>
          {Object.keys(unsignedAgreements).length > 0
            && unsignedAgreements.map((a) => (
              <div key={a.id}>
                <DefaultAgreementCard
                  id={a.id}
                  name={a.agreement.name}
                  selected={a.id === selectedAgreement.id}
                  signed={a.signed}
                  signedOn={a.signed_on !== null ? a.signed_on.replace('UTC', 'GMT') : null}
                  assignedOn={a.assigned_on !== null ? a.assigned_on.replace('UTC', 'GMT') : null}
                  selectFunc={handleSelectAgreement}
                />
                <Divider />
              </div>
            ))}
        </Grid>
        <Grid item md={6}>
          {Object.keys(signedAgreements).length > 0
            && signedAgreements.map((a) => (
              <div key={a.id}>
                <DefaultAgreementCard
                  id={a.id}
                  name={a.agreement.name}
                  selected={a.id === selectedAgreement.id}
                  signed={a.signed}
                  signedOn={a.signed_on}
                  assignedOn={a.assigned_on}
                  selectFunc={handleSelectAgreement}
                />
                <Divider />
              </div>
            ))}
        </Grid>
      </Grid>
      <Drawer
        id="agreementDrawer"
        anchor="right"
        open={showAgreement}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { width: '60%' },
        }}
      >
        <AgreementInfo
          id={selectedAgreement.id}
          name={selectedAgreement.agreement.name}
          fileUrl={selectedAgreement.file_url}
          signed={selectedAgreement.signed}
          signedOn={selectedAgreement.signed_on}
          uploadFunc={handleUploadFile}
          assignedOn={selectedAgreement.assigned_on}
          expiresOn={selectedAgreement.expires_on}
          signFunc={handleSign}
          modifyFunc={handleModify}
          removeFunc={handleRemove}
          showModify={checkPermission('CAPV', currentUser)}
        />
      </Drawer>
      <Modal
        open={openAssign}
        onClose={handleClose}
        aria-labelledby="Validate Agreement"
        aria-describedby="Review template variables"
      >
        <Card sx={cardStyle}>
          <CardHeader title={t('variables')} />
          <CardContent>
            <Grid container spacing={3}>
              {Object.keys(templateVariables).length > 0
                && templateVariables.map((v, i) => (
                  <Grid item xs={6} md={6} key={`variable${i}`}>
                    <FormField
                      label={v.replace('<%= ', '').replace(' %>', '')}
                      textTransform="none"
                      value={
                        variablesValues[v.replace('<%= ', '').replace(' %>', '')]
                      }
                      error={
                        variablesValues[v.replace('<%= ', '').replace(' %>', '')] === ''
                        || variablesValues[v.replace('<%= ', '').replace(' %>', '')] === undefined
                      }
                      onChange={(e) => setValue(
                        v.replace('<%= ', '').replace(' %>', ''),
                        e.target.value,
                      )}
                    />
                  </Grid>
                ))}
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <SuiButton variant="gradient" size="small" onClick={handleAssign}>
                {t('assign', { keyPrefix: 'common' })}
              </SuiButton>
            </Grid>
          </CardActions>
        </Card>
      </Modal>
    </div>
  );
}

export default Agreements;
