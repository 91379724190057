import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import {
  fetchCandidateNclexAssessmentTests,
  fetchCandidateNclexs,
  fetchCandidateNclexTests,
  fetchCandidatePearson,
  setCurrentSubView,
  setNclex,
  setNclexAssessmentTests,
  setNclexTests,
  setPearsonRegistrations,
} from 'reducers/candidatesSlice';
import { fetchCountries } from 'reducers/commonSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import SuiAlert from 'components/SuiAlert';

// Components
import FormField from 'layouts/components/FormField';
import FormPasswordInput from 'layouts/components/FormPasswordInput';
import DefaultNclexCard from 'layouts/components/Cards/NclexCards/DefaultNclexCard';
import DefaultNclexAssessmentCard from 'layouts/components/Cards/NclexCards/DefaultNclexAssessmentCard';
import DefaultPearsonCard from 'layouts/components/Cards/PearsonVueCards/DefaultPearsonVueCards';

// Functions
import {
  checkPermission,
  defaultValue, getCandidateUUID, isValidValue, setTitle,
} from 'Util';

require('datejs');

const selector = (state) => ({
  currentUser: state.auth.user,
  editing: state.candidate.editing,
  nclex: state.candidate.nclex,
  nclexTests: state.candidate.nclexTests,
  nclexAssessmentTests: state.candidate.nclexAssessmentTests,
  pearsonRegistrations: state.candidate.pearsonRegistrations,
  countries: state.common.countries,
  documents: state.document.documents,
});

function Nclex() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.nclex',
  });
  const {
    currentUser,
    editing,
    nclex,
    nclexTests,
    nclexAssessmentTests,
    pearsonRegistrations,
    countries,
    documents,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [readyToTest, setReadyToTest] = useState(false);

  useEffect(() => {
    dispatch(setCurrentSubView('nclex'));
    dispatch(fetchCountries());
    dispatch(fetchDocuments());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateNclexs({ uuid }));
      dispatch(fetchCandidateNclexTests({ uuid }));
      dispatch(fetchCandidateNclexAssessmentTests({ uuid }));
      dispatch(fetchCandidatePearson({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  useEffect(() => {
    if (nclex !== undefined) {
      setReadyToTest(nclex.ready_to_test);
    }
  }, [nclex]);

  useEffect(() => {
    if (nclexAssessmentTests !== undefined) {
      const count = nclexAssessmentTests.filter((obj) => obj.changed === true).length;

      if (count === 0) {
        if (editing && uuid !== undefined) {
          dispatch(fetchCandidateNclexs({ uuid }));
        }
      }
    }
  }, [dispatch, editing, uuid, nclexAssessmentTests]);

  setTitle('Candidate NCLEX');

  const handleAddNclex = () => {
    dispatch(
      setNclexTests(
        nclexTests.concat({
          id: undefined,
          user: '',
          password: '',
          nclex_result_id: undefined,
          schedule: '',
          country_id: undefined,
          changed: true,
          valid: false,
          documents: [],
        }),
      ),
    );
  };

  const setValue = (key, value) => {
    dispatch(
      setNclex(
        {
          ...nclex,
          [key]: value,
          changed: true,
          valid: true, // isValid(key, value, nclex),
        },
      ),
    );
  };

  const isValidTest = (key, value, r) => {
    const objToValidate = {
      ...r,
      [key]: value,
    };

    return isValidValue(objToValidate.att_number)
      && isValidValue(objToValidate.att_received)
      && isValidValue(objToValidate.att_expiration);
  };

  const setTestValue = (key, value, i) => {
    if (key === 'att_received') {
      const received = Date.parse(`${value}T00:00:00`);
      const expiration = Date.parse(`${value}T00:00:00`);
      expiration.add(3).months();

      dispatch(
        setNclexTests(
          nclexTests.map((obj, index) => {
            if (index === i) {
              return {
                ...obj,
                att_received: received.toString('yyyy-MM-dd'),
                att_expiration: expiration.toString('yyyy-MM-dd'),
                changed: true,
                valid: isValidTest(key, value, nclexTests[i]),
              };
            }
            return obj;
          }),
        ),
      );
    } else {
      dispatch(
        setNclexTests(
          nclexTests.map((obj, index) => {
            if (index === i) {
              return {
                ...obj,
                [key]: value,
                changed: true,
                valid: isValidTest(key, value, nclexTests[i]),
              };
            }
            return obj;
          }),
        ),
      );
    }
  };

  const handleRemoveTest = (i) => {
    if (nclexTests[i].id !== undefined) {
      setTestValue('_destroy', true, i);
    } else {
      dispatch(
        setNclexTests([...nclexTests.slice(0, i), ...nclexTests.slice(i + 1)]),
      );
    }
  };

  const handleAddNclexAssessment = () => {
    dispatch(
      setNclexAssessmentTests(
        nclexAssessmentTests.concat({
          id: undefined,
          date: '',
          score: '',
          result: '',
          questions: 0,
          challenging_topic_id: undefined,
          nclex_readiness_id: undefined,
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const isValidAssessment = (key, value, r) => {
    const objToValidate = {
      ...r,
      [key]: value,
    };

    return isValidValue(objToValidate.date)
      && isValidValue(objToValidate.result)
      && isValidValue(objToValidate.questions)
      && isValidValue(objToValidate.nclex_readiness_id);
  };

  const setAssessmentValue = (key, value, i) => {
    dispatch(
      setNclexAssessmentTests(
        nclexAssessmentTests.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValidAssessment(key, value, nclexAssessmentTests[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleRemoveAssessment = (i) => {
    if (nclexAssessmentTests[i].id !== undefined) {
      setAssessmentValue('_destroy', true, i);
    } else {
      dispatch(
        setNclexAssessmentTests([
          ...nclexAssessmentTests.slice(0, i),
          ...nclexAssessmentTests.slice(i + 1),
        ]),
      );
    }
  };

  const handleAddPersonVue = () => {
    dispatch(
      setPearsonRegistrations(
        pearsonRegistrations.concat({
          id: undefined,
          user: '',
          password: '',
          registration_date: undefined,
          ncsbn_id: undefined,
          payment_date: undefined,
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const isValidPearson = (key, value, r) => {
    const objToValidate = {
      ...r,
      [key]: value,
    };

    return isValidValue(objToValidate.registration_date);
  };

  const setPearsonValue = (key, value, i) => {
    dispatch(
      setPearsonRegistrations(
        pearsonRegistrations.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValidPearson(key, value, pearsonRegistrations[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleRemovePearson = (i) => {
    if (pearsonRegistrations[i].id !== undefined) {
      setPearsonValue('_destroy', true, i);
    } else {
      dispatch(
        setPearsonRegistrations(
          [...pearsonRegistrations.slice(0, i), ...pearsonRegistrations.slice(i + 1)],
        ),
      );
    }
  };

  return (
    <SuiBox component="form">
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('nclex')}
            </SuiTypography>
            <SuiBox mt={1} mb={2}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <SuiTypography variant="body2" color="text">
                    {t('nclex-description')}
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} md={12}>
                  {readyToTest && (
                    <SuiAlert color="success">
                      <IconButton size="large">
                        <TaskAltIcon color="light" />
                      </IconButton>
                      <SuiTypography variant="body3" color="light">
                        {t('ready-for-nclex-test')}
                      </SuiTypography>
                    </SuiAlert>
                  )}
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
        {checkPermission('CNED', currentUser) && (
          <>
            <Card sx={{ overflow: 'visible' }}>
              <CardHeader
                title={`${t('expected-date')}`}
              />
              <CardContent>
                <SuiBox component="form" pb={1} px={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <FormField
                        label={t('date')}
                        type="date"
                        error={nclex.expected_date ? false
                          : !nclex.expected_date || nclex.expected_date === ''}
                        onChange={(event) => {
                          setValue('expected_date', event.target.value);
                        }}
                        value={defaultValue(nclex.expected_date)}
                      />
                    </Grid>
                  </Grid>
                </SuiBox>
              </CardContent>
            </Card>
            <Divider />
          </>
        )}
        {checkPermission('CNEM', currentUser) && (
          <>
            <Card sx={{ overflow: 'visible' }}>
              <CardHeader
                title={`${t('educational-materials')}`}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} xl={12}>
                    <SuiTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {t('archer')}
                    </SuiTypography>
                    <SuiBox component="form" pb={1} px={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                          <FormField
                            id="archer-date"
                            label={t('date')}
                            type="date"
                            error={nclex.archer_registration_date ? false
                              : !nclex.archer_registration_date || nclex.archer_registration_date === ''}
                            onChange={(event) => {
                              setValue('archer_registration_date', event.target.value);
                            }}
                            value={defaultValue(nclex.archer_registration_date)}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormField
                            id="archer-expiration"
                            label={t('expiration')}
                            type="date"
                            error={nclex.archer_expiration_date ? false
                              : !nclex.archer_expiration_date || nclex.archer_expiration_date === ''}
                            onChange={(event) => {
                              setValue('archer_expiration_date', event.target.value);
                            }}
                            value={defaultValue(nclex.archer_expiration_date)}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormField
                            id="archer-user"
                            label={t('user')}
                            error={nclex.archer_username ? false
                              : !nclex.archer_username || nclex.archer_username === ''}
                            onChange={(event) => {
                              setValue('archer_username', event.target.value);
                            }}
                            value={defaultValue(nclex.archer_username)}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormPasswordInput
                            id="archer-password"
                            label={t('password')}
                            error={nclex.archer_password ? false
                              : !nclex.archer_password || nclex.archer_password === ''}
                            onChange={(event) => {
                              setValue('archer_password', event.target.value);
                            }}
                            value={defaultValue(nclex.archer_password)}
                          />
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} xl={12}>
                    <SuiTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {t('saunders')}
                    </SuiTypography>
                    <SuiBox component="form" pb={1} px={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                          <FormField
                            id="saunders-date"
                            label={t('date')}
                            type="date"
                            error={nclex.saunders_registration_date ? false
                              : !nclex.saunders_registration_date || nclex.saunders_registration_date === ''}
                            onChange={(event) => {
                              setValue('saunders_registration_date', event.target.value);
                            }}
                            value={defaultValue(nclex.saunders_registration_date)}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormField
                            id="saunders-expiration"
                            label={t('expiration')}
                            type="date"
                            error={nclex.saunders_expiration_date ? false
                              : !nclex.saunders_expiration_date || nclex.saunders_expiration_date === ''}
                            onChange={(event) => {
                              setValue('saunders_expiration_date', event.target.value);
                            }}
                            value={defaultValue(nclex.saunders_expiration_date)}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormField
                            id="saunders-user"
                            label={t('user')}
                            error={nclex.saunders_username ? false
                              : !nclex.saunders_username || nclex.saunders_username === ''}
                            onChange={(event) => {
                              setValue('saunders_username', event.target.value);
                            }}
                            value={defaultValue(nclex.saunders_username)}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormPasswordInput
                            id="saunders-password"
                            label={t('password')}
                            error={nclex.saunders_password ? false
                              : !nclex.saunders_password || nclex.saunders_password === ''}
                            onChange={(event) => {
                              setValue('saunders_password', event.target.value);
                            }}
                            value={defaultValue(nclex.saunders_password)}
                          />
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Divider />
          </>
        )}
        {checkPermission('CNPV', currentUser) && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <SuiTypography variant="h4" fontWeight="medium">
                  {t('pearson-vue')}
                </SuiTypography>
                <SuiBox mt={1} mb={2}>
                  <SuiTypography variant="body2" color="text">
                    {t('pearson-vue-description')}
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SuiBox display="flex" justifyContent="flex-end">
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={handleAddPersonVue}
                  >
                    {t('new-pearson-vue')}
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={12}>
                <SuiBox pb={1} px={1}>
                  {Object.keys(pearsonRegistrations).length > 0
                    && pearsonRegistrations
                      // eslint-disable-next-line no-underscore-dangle
                      .filter((r) => r._destroy !== true)
                      .map((r, i) => (
                        <div key={`pearson-vue-${i}`}>
                          <DefaultPearsonCard
                            i={i}
                            r={r}
                            setValue={setPearsonValue}
                            removeFunc={handleRemovePearson}
                            hasPaymentDate={Object.keys(pearsonRegistrations).length !== i + 1}
                            loginInfo={checkPermission('CPVL', currentUser)}
                            permissions={{
                              loginInfo: checkPermission('CPVL', currentUser),
                              dates: checkPermission('CPVD', currentUser),
                              ncsbn: checkPermission('CPVN', currentUser),
                            }}
                          />
                          <Divider />
                        </div>
                      ))}
                </SuiBox>
              </Grid>
            </Grid>
          </>
        )}
        {checkPermission('CNNT', currentUser) && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <SuiTypography variant="h4" fontWeight="medium">
                  {t('nclex-tests')}
                </SuiTypography>
                <SuiBox mt={1} mb={2}>
                  <SuiTypography variant="body2" color="text">
                    {t('nclex-tests-description')}
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SuiBox display="flex" justifyContent="flex-end">
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={handleAddNclex}
                  >
                    {t('new-nclex')}
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={12}>
                <SuiBox pb={1} px={1}>
                  {Object.keys(nclexTests).length > 0
                    && nclexTests
                      // eslint-disable-next-line no-underscore-dangle
                      .filter((n) => n._destroy !== true)
                      .map((n, i) => (
                        <div key={`nclex-test-${i}`}>
                          <DefaultNclexCard
                            i={i}
                            c={n}
                            ops={{
                              documents: documents
                                .filter((doc) => doc.document_type_id === 11),
                              countries,
                            }}
                            setValue={setTestValue}
                            removeFunc={handleRemoveTest}
                            candidateId={uuid}
                            permissions={{
                              attNum: checkPermission('CNAN', currentUser),
                              attReceived: checkPermission('CNAR', currentUser),
                              attExpiration: checkPermission('CNAE', currentUser),
                              schedule: checkPermission('CNAT', currentUser),
                              country: checkPermission('CNAC', currentUser),
                              result: checkPermission('CNAS', currentUser),
                              document: checkPermission('CNAD', currentUser),
                            }}
                          />
                          <Divider />
                        </div>
                      ))}
                </SuiBox>
              </Grid>
            </Grid>
            <Divider />
          </>
        )}
        {checkPermission('CNTA', currentUser) && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <SuiTypography variant="h4" fontWeight="medium">
                  {t('nclex-assessment-tests')}
                </SuiTypography>
                <SuiBox mt={1} mb={2}>
                  <SuiTypography variant="body2" color="text">
                    {t('nclex-assessment-tests-description')}
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SuiBox display="flex" justifyContent="flex-end">
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={handleAddNclexAssessment}
                  >
                    {t('new-nclex-assessment')}
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={12}>
                <SuiBox pb={1} px={1}>
                  {Object.keys(nclexAssessmentTests).length > 0
                    && nclexAssessmentTests
                      // eslint-disable-next-line no-underscore-dangle
                      .filter((r) => r._destroy !== true)
                      .map((n, i) => (
                        <div key={`nclex-assessment-test-${i}`}>
                          <DefaultNclexAssessmentCard
                            i={i}
                            c={n}
                            setValue={setAssessmentValue}
                            removeFunc={handleRemoveAssessment}
                          />
                          <Divider />
                        </div>
                      ))}
                </SuiBox>
              </Grid>
            </Grid>
          </>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default Nclex;
