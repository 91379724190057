import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  setCreated,
  setFailed,
  setProfile,
  setSaved,
  updateCandidateProfile,
} from 'reducers/candidatesSlice';

// @mui material components
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTagInput from 'components/SuiTagInput';
import SuiButton from 'components/SuiButton';

// Functions
import {
  failedCandidateMessage,
  getCandidateUUID,
  savedCandidateMessage,
  setTitle,
} from 'Util';

const selector = (state) => ({
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  profile: state.candidate.profile,
});

function RecruitmentPreferences() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.recruitment',
  });
  const {
    profile,
    created,
    saved,
    failed,
    errors,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  setTitle('Candidate Recruitment');

  const setValue = (key, value) => {
    dispatch(
      setProfile(
        {
          ...profile,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleSave = () => {
    const isValid = (profile.last_name !== '' && profile.last_name !== undefined)
      && (profile.first_name !== '' && profile.first_name !== undefined)
      && (profile.country_id !== '' && profile.country_id !== undefined);

    if (isValid) {
      const data = {
        recruitmentList: profile.recruitment_list,
      };

      data.uuid = uuid;
      dispatch(updateCandidateProfile(data));
    }
  };

  if (created) {
    dispatch(setCreated(false));
    savedCandidateMessage(t);
  }
  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SuiBox p={1}>
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          title={t('preferences')}
        />
        <SuiBox p={1} mb={2}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <SuiTagInput
                placeholder={t('add-new-tag')}
                size="large"
                tags={profile.recruitment_list !== undefined ? profile.recruitment_list : []}
                onChange={(event) => {
                  setValue('recruitment_list', event);
                }}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
      <SuiBox mt={5} mb={3}>
        <SuiBox display="flex" justifyContent="flex-end" mt={6}>
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleSave}
            >
              {t('save', { keyPrefix: 'common' })}
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default RecruitmentPreferences;
